import {
    Bars3Icon,
    CalendarIcon,
    HomeIcon,
    ArrowUpIcon,
    ArrowUpOnSquareIcon,
    MagnifyingGlassCircleIcon,
    MapIcon,
    MegaphoneIcon,
    UserGroupIcon,
    XMarkIcon,
    InboxIcon,
    UsersIcon,
    CogIcon,
    DocumentIcon,
    QuestionMarkCircleIcon,
    ChartPieIcon,
    ArrowLeftOnRectangleIcon,
    ArrowDownTrayIcon,
    EnvelopeIcon,
    BellIcon,
    EnvelopeOpenIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon, BookOpenIcon, ClipboardDocumentIcon, CloudIcon, ShoppingCartIcon, TruckIcon
} from '@heroicons/vue/24/outline';

export default [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false, order: 0 },
    { name: 'Bestellungen', href: '/dashboard/Bestellungen', icon: ShoppingCartIcon, current: false, order: 1 },
    { name: 'Storys', href: '/dashboard/storys', icon: DocumentIcon, current: false, order: 2 },
    { name: 'Team', href: '/dashboard/Team', icon: UserGroupIcon, current: false, order: 3 },
    { name: 'Artikel', href: '/dashboard/Artikel', icon: BookOpenIcon, current: false, order: 4 },
    { name: 'Produkte', href: '/dashboard/Produkte', icon: ClipboardDocumentIcon, current: false, order: 5 },
    { name: 'Produktwelt', href: '/dashboard/Produktwelt', icon: ClipboardDocumentIcon, current: false, order: 5.1 },
    { name: 'Wollannahmen', href: '/dashboard/Wollannahmen', icon: CloudIcon, current: false, order: 6 },
    { name: 'FAQ', href: '/dashboard/FAQ', icon: QuestionMarkCircleIcon, current: false, order: 7 },
    { name: 'Partner', href: '/dashboard/partner', icon: UsersIcon, current: false, order: 8 },
    { name: 'Promocodes', href: '/dashboard/promocodes', icon: MegaphoneIcon, current: false, order: 9 },
    { name: 'Mengenrabatt', href: '/dashboard/mengenrabatt', icon: ChartPieIcon, current: false, order: 10 },
    { name: 'Einstellungen', href: '/dashboard/Einstellungen', icon: CogIcon, current: false, order: 11 },
    { name: 'Lager', href: '/dashboard/Lager', icon: TruckIcon, current: false, order: 12 }
]